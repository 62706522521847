<template>
  <v-card
    class="fill-height"
  >
    <v-card-title
      class="pb-1"
    >
      {{ statisticTitle }}
      <v-spacer></v-spacer>
      <v-icon
        class="text--disabled"
      >
        mdi-help-circle-outline
      </v-icon>
    </v-card-title>
    <vue-apex-charts
      :options="chartOptions"
      :series="seriesData"
      type="radialBar"
      height="240"
    />
    <v-card-text>
      <v-row>
        <v-col
          cols="6"
          class="text-center"
        >
          <div
            class="body-2 font-weight-black"
          >
            {{ totalLabel }}
          </div>
          <div
            class="display-1 font-weight-black"
          >
            {{ totalValue }}
          </div>
        </v-col>
        <v-col
          cols="6"
          class="text-center"
        >
          <div
            class="body-2 font-weight-black"
          >
            {{ partLabel }}
          </div>
          <div
            class="display-1 font-weight-black"
          >
            {{ partValue }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
  import 'echarts/lib/chart/pie'

  export default{
    name: 'StatisticsCardRadialBar',

    components: {

    },

    props: {
      statisticTitle: {
        type: String,
        default: () => ''
      },
      totalValue: {
        type: Number,
        required: true,
        default: () => 0
      },
      partValue: {
        type: Number,
        default: () => 0
      },
      totalLabel: {
        type: String,
        required: true,
        default: () => ''
      },
      partLabel: {
        type: String,
        required: true,
        default: () => ''
      },
      color: {
        type: String,
        default: '#FF1744',
      },
      colorTo: {
        type: String,
        default: '#EF9A9A'
      }
    },


    data() {
      return {
        chartOptions: {
          grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 5,
                left: 0
            }
          },
          plotOptions: {
            radialBar: {
              size: 110,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: "#bfc5cc",
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: 18,
                  color: '#99a2ac',
                  fontSize: '4rem'
                }
              }
            }
          },
          colors: [this.color],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [this.colorTo],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1
            }
          },
        }
      }
    },

    computed: {
      seriesData () {
        if (this.totalValue > 0 && this.partValue > 0) {
          return [ Math.round( this.partValue / this.totalValue  * 100 )]
        } else {
          return [0]
        }
      }
    },

    methods: {

    },

    created() {

    }
  }
</script>

